var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"vx-row mb-12"},[_c('div',{class:[
          _vm.detail ? _vm.detailShow + ' vertical-divider' : '',
          _vm.detailHide,
        ]},[_c('data-table',{attrs:{"responseData":_vm.responseData,"propsParams":_vm.params,"header":_vm.header},on:{"reloadDataFromChild":_vm.reloadData}},[_c('template',{slot:"thead"}),_c('template',{slot:"tbody"},_vm._l((_vm.responseData.records),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{staticClass:"whitespace-no-wrap"},[_c('div',{staticClass:"mt-4 flex mr-1"},[_c('vx-tooltip',{staticClass:"mr-4",attrs:{"text":"Show"}},[_c('vs-button',{attrs:{"color":"green","type":"line","icon-pack":"feather","icon":"icon-eye"},on:{"click":function($event){return _vm.edit(tr)}}})],1)],1)]),_c('vs-td',[_vm._v(" "+_vm._s(tr.shipment_number)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.dateFormat(tr.delivery_plan_date))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.count_do_pickup)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.driver_name)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.vehicle_name)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.shipment_cost_code)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(((tr.vehicle_type === '' || tr.vehicle_type === null || typeof tr.vehicle_type === null )?'OnCall':(tr.vehicle_type.toLowerCase() == 'internal'?'Internal':'Oncall')))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.advance_cash_value==null?"":_vm.formatPrice(tr.advance_cash_value))+" ")])],1)}),1)],2)],1),_c('transition',{attrs:{"name":"detail-fade"}},[(_vm.detail)?_c('div',{class:[
            _vm.detail ? ' nonfixed vx-col md:w-2/3 w-full mb-base' : '',
            _vm.detailHide,
          ]},[_c('div',[_c('vs-col',{staticStyle:{"padding-bottom":"8px"},attrs:{"vs-offset":"8","vs-type":"flex","vs-justify":"rigth","vs-align":"rigth","vs-w":"4"}},[_c('vs-button',{attrs:{"size":"small","color":"grey","icon-pack":"feather","icon":"icon-x-square"},on:{"click":_vm.closeDetail}},[_vm._v("Close")])],1),(_vm.selectedData.ID == 0)?[_c('create',{on:{"closeDetail":_vm.closeDetail}})]:[_c('edit',{attrs:{"selected":_vm.selectedData},on:{"closeDetail":_vm.closeDetail}})]],2)]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }